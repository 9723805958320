const tenantCustonConfig = {
  brandName: "GetNet",
  dropdownMenuItems: [
    "upgradeToProfessionalLink",
    "buyReader",
    "staticQRCode",
  ],
  currencyDecimals:true,
  automaticCashout: {
    isEnabled: false,
  },
  termsAndConditions: false,
  stats: {
    statsEnabled: ["products", "staff"],
  },
  sales: {
    showReferenceNumberFilter: true,
    transactionReceipt: {
      salesTerminalPayment: true,
      qr: [
        {
          label: "Titular de tarjeta",
          method: (sale) => sale.payment.cardHolderName,
        },
        {
          label: "Tasa comisión",
          method: (sale) => sale.commission + "%",
        },
        {
          label: "Fecha de disponibilidad",
          method: (sale) => sale.availableDate,
        },
        {
          label: "N° de referencia",
          method: (sale) => sale.referenceNumber,
        },
        {
          label: "Nº de autorización",
          method: (sale) => sale.authorizationCode,
        },
      ],
    },
  },
  deposits: {
    options: {
      formats: [
        { value: 'xls', label: 'Excel' },
        { value: 'pdf', label: 'PDF' }
      ],
    },
  },
  paymentLink: {
    biometricValidation: true,
    bankAccountValidation: true,
    hideWhenDisabled: true,
  },
};

const tenantTableColumns = {
  salesColumns: ["description", "date", "terminalPayment", "amount"],
};

export { tenantCustonConfig, tenantTableColumns };
