import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import __ from 'utils/i18n';
import { DropdownItem } from 'reactstrap';
import { PermissionsContext } from 'contexts/permissionsContext';
import { onboardingUrl } from 'config/envConfig';
import { fetchResource } from "api-provider";
import { isValidToken } from "../../../../../Login/resources";
import { getConfig } from "config";

export const UpgradeToProfessionalLink = () => {
  const { userPermissions, featureFlags } = useContext(PermissionsContext);
  const accountType = useSelector((state) => state.login?.user?.accountType);
  const mustUsePublicToken = featureFlags?.includes("auth_server_enabled") || featureFlags?.includes("keycloak_enabled");
  const token = useSelector((state) => state.api?.token);

  const isUpgradeToProfessionalEnable =
    accountType === "OCCASIONAL" &&
    featureFlags?.includes("user_menu_upgrade_professional_account") &&
    userPermissions?.includes("user_menu_upgrade_professional_account");

  const redirectToOnboarding = () => {
    const path = getConfig('customConfig.onBoardingCustomPath');
    if(mustUsePublicToken) {
      fetchResource(isValidToken(token))
        .onSuccess((data) => {
          window.open(`${onboardingUrl}/${path}${data.token}`, '_blank');
        })
    }else {
      window.open(`${onboardingUrl}/${path}${token}`, '_blank');
    }
  }

  return (
    <>
      {isUpgradeToProfessionalEnable && (
        <DropdownItem className="nav-menu-item" onClick={redirectToOnboarding}>
          <i className="mdi mdi-18px mdi-arrow-up-bold" /><span>{__('dashboard.components.upgradeToProfessional')}</span>
        </DropdownItem>
      )}
    </>
  )
}
