const headers = (token, contentType = "application/json") => {
  var headerObject = {
    "Authorization": token,
  };

  return headerObject;
};

export const getExternalReaderBuyUrl= (token) => ({
    endpoint: 'external/buy-reader',
    method: 'GET',
    headers: headers(token),
    parseResponse: response => response.data?.url,
  })
